<template>
 <div class="min-h-screen flex flex-col bg-white">
  <main class="grid min-h-full place-items-center bg-white">
   <div class="text-center">
    <p class="text-base font-semibold encom_primary_text">403</p>
    <h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
     {{ $t("unauthorizedAction") }}
    </h1>
    <p class="mt-3 text-base leading-7 text-gray-600">
     {{ $t("notAuthorized") }}
    </p>

    <div class="mt-10 flex items-center justify-center gap-x-6">
     <a
      :href="
       userPermissions && userPermissions.permissions && userPermissions.permissions.includes('ticketing.*') ||
       userPermissions && userPermissions.permissions && userPermissions.permissions.includes('ticketing.view') ||
       userPermissions && userPermissions.permissions && userPermissions.permissions.includes('ticketing.view-own')
        ? '/'
        : '/get-started'
      "
      class="rounded-md encom_primary encom_primary_hover px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
     >
      {{ $t("goBackHome") }}
     </a>
    </div>
   </div>
  </main>
 </div>
</template>

<script>
// const userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
import { mapState } from "vuex";

export default {
 name: "Error404",
 props: ["msg", "status"],
 data() {
  return {};
 },
 methods: {
  signOut() {
   localStorage.removeItem("token");
   localStorage.removeItem("adminToken");
   localStorage.removeItem("avatar");
   localStorage.removeItem("account");
   this.$store.dispatch("user", {});
   this.$store.dispatch("adminToken", "");
   this.$store.dispatch("token", "");
   this.$store.dispatch("userAvatar", "");
   this.$store.dispatch("account", "");
   this.$router.push("/login");
   location.reload();
  },
 },
 mounted() {
  this.$emit("setLoading", false);
 },
 computed: {
  ...mapState({
   // This will ensure the component receives reactive updates from the store
   userPermissions: (state) => state.userPermissions,
  }),
 },
};
</script>

<style></style>
